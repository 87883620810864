<template>
    <!-- 联系我们 -->
    <div class="zz_contactUs1">
      <div
        class="current-nav centerText"
        v-if="Object.keys(lxData).length != 0"
        style="height: 565px"
        :style="{
          background: `url(${lxData.lxwmImg}) 100%/100% no-repeat`
        }"
      >
      </div>
      <div class="all_no_data" v-else>
        <i class="el-icon-loading"></i>
        <p>数据正在加载</p>
      </div>
      <div class="zz_contactUs1_nav">
        <div class="current-title">
          <span>{{ lxData.lxTitle }}</span>
          <p>
            {{ lxData.lxText }}
          </p>
        </div>
        <div class="nav_container">
          <ul class="current-lxfs">
            <li v-for="(el, i) in lxData.lxList" :key="i">
              <div class="li_icon zz_all_img">
                <img
                  :src="el.value"
                  alt=""
                  style="height:100%"
                />
              </div>
              <div class="li_text">
                <div class="li_text_nav" v-html="el.key"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getPageMsg } from '@/api/api'
  export default {
    components: {},
    data () {
      return {
        lxData: {},
      }
    },
    methods: {
      getData () {
        let id = sessionStorage.getItem(this.changeData() + 'id')
        getPageMsg(id).then(res => {
          if (res.data.code == 1) {
            this.lxData = res.data.data.lxwm
          }
        })
      }
    },
    mounted () {
      this.getData()
    }
  }
  </script>
  <style lang="less" scoped>
  @import url('~@/assets/css/mixins.less'); //混合
  .zz_contactUs1 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .zz_contactUs1_nav {
      padding: 0 0 80px;
      background: #f2f2f2;
      .nav_title {
        text-align: center;
        padding: 60px 0 50px;
        h2 {
          font-size: 36px;
          color: #161616;
        }
        p {
          font-size: 15px;
          color: #888;
          margin-top: 15px;
        }
      }
      .nav_container {
        width: 1100px;
        background-color: #fff;
        padding: 50px;
        display: flex;
        justify-content: space-between;
        .current-lxfs {
          border-left: solid 1px #e6e6e6;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          li {
            width: 25%;
            border-right: solid 1px #e6e6e6;
            border-bottom: solid 1px #e6e6e6;
            border-top: solid 1px #e6e6e6;
            box-sizing: border-box;
            text-align: center;
            padding-bottom: 30px;
            .li_icon {
              height: 138px;
              margin-top: 45px;
            }
            .li_text {
              min-height: auto;
              font-size: 18px;
              color: #666666;
              margin-top: 40px;
              padding: 0 30px;
              line-height: 1.5;
              .li_text_nav {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
  .nav_container {
    padding: 0 15px;
    margin: 0 auto;
  }
  </style>
  